<template>
	<el-drawer
		:close-on-press-escape="false"
		:wrapperClosable="false"
		title="财务科目导入"
		:visible.sync="drawer_"
		direction="rtl"
		custom-class="demo-drawer"
		ref="drawer"
		size="80%"
		@open="open"
		@close="
			() => {
				$refs['diyRuleForm'].resetFields();
				Object.assign($data, $options.data());
				$emit('closeVisible');
			}
		"
	>
		<div class="demo-drawer__content" style="width: 100%">
			<div class="edit" style="padding: 0 30px; width: 100%">
				<div
					class="rtnDataList"
					v-if="rtnDataList.length"
					style="
						padding: 15px 30px;
						background-color: rgb(252, 235, 234);
						width: 95%;
						margin: 5px auto;
						display: block;
					"
				>
					<div
						style="
							color: #d93026;
							font-weight: 800;
							font-size: 13px;
							margin-bottom: 10px;
						"
					>
						<i class="el-icon-error"></i> 保存失败，错误信息如下:
					</div>
					<div
						style="
							color: #d93026;
							font-size: 12px;
							text-indent: 13px;
							line-height: 16px;
						"
					>
						<p v-for="(item, index) in rtnDataList" :key="index">
							{{ item.queryValue }},{{ item.dataMessage }}
						</p>
					</div>
				</div>
				<el-form
					:model="ruleForm"
					status-icon
					ref="diyRuleForm"
					class="demo-ruleForm"
					label-position="rigth"
					label-width="170px"
				>
					<el-form-item
						id="v-step-0"
						label="财务日期:"
						:rules="[
							{
								required: true,
								message: '财务日期不能为空',
							},
						]"
						prop="financeDate"
					>
						<el-date-picker
							type="month"
							placeholder="选择日期"
							format="yyyy-MM"
							value-format="yyyy-MM"
							style="width: 100%"
							v-model="ruleForm.financeDate"
						>
						</el-date-picker>
					</el-form-item>

					<el-form-item
						id="v-step-1"
						label="模板类型:"
						:rules="[
							{
								required: true,
								message: '请选择模板类型',
							},
						]"
						prop="templateTypeId"
					>
						<el-select
							style="width: 100%"
							v-model="ruleForm.templateTypeId"
							placeholder="请选择"
							@change="change"
						>
							<el-option
								v-for="item in FinanceSubjectTemplateType"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<div class="diy-table">
						<div>
							<span style="justify-content: center"
								>字段名称</span
							>
							<span style="justify-content: center; flex: 1.2"
								>是否匹配<el-tooltip
									effect="dark"
									placement="right"
								>
									<span
										v-html="
											'选中代表根据填写的Excel标题名称去匹配Excel中的数据，取消选中则代表不匹配Excel中的数据'
										"
										slot="content"
									></span>
									<i
										class="icon iconfont icon-wenhao"
									></i> </el-tooltip
							></span>
							<span style="justify-content: center"
								>Excel标题名称</span
							>
							<span style="justify-content: center; flex: 1.2"
								>X轴偏移量<el-tooltip
									effect="dark"
									placement="right"
								>
									<span
										v-html="
											'1、只能填写数字；</br>2、填写正整数代表在Excel标题名称所在单元格的X轴坐标基础上往右移动几个单元格；</br>3、填写负整数代表在Excel标题名称所在单元格的X轴坐标基础上往左移动几个单元格；'
										"
										slot="content"
									></span>
									<i
										class="icon iconfont icon-wenhao"
									></i> </el-tooltip
							></span>
							<span style="justify-content: center; flex: 1.2"
								>Y轴偏移量<el-tooltip
									effect="dark"
									placement="right"
								>
									<span
										v-html="
											'1、只能填写数字；</br>2、填写正整数代表在Excel标题名称所在单元格的Y轴坐标基础上往下移动几个单元格；</br>3、填写负整数代表在Excel标题名称所在单元格的Y轴坐标基础上往上移动几个单元格；'
										"
										slot="content"
									></span>
									<i
										class="icon iconfont icon-wenhao"
									></i> </el-tooltip
							></span>
							<span style="justify-content: center"
								>字段名称</span
							>
							<span style="justify-content: center; flex: 1.2"
								>是否匹配<el-tooltip
									effect="dark"
									placement="right"
								>
									<span
										v-html="
											'选中代表根据填写的Excel标题名称去匹配Excel中的数据，取消选中则代表不匹配Excel中的数据'
										"
										slot="content"
									></span>
									<i
										class="icon iconfont icon-wenhao"
									></i> </el-tooltip
							></span>
							<span style="justify-content: center"
								>Excel标题名称</span
							>
							<span style="justify-content: center; flex: 1.2"
								>X轴偏移量<el-tooltip
									effect="dark"
									placement="right"
								>
									<span
										v-html="
											'1、只能填写数字；</br>2、填写正整数代表在Excel标题名称所在单元格的X轴坐标基础上往右移动几个单元格；</br>3、填写负整数代表在Excel标题名称所在单元格的X轴坐标基础上往左移动几个单元格；'
										"
										slot="content"
									></span>
									<i
										class="icon iconfont icon-wenhao"
									></i> </el-tooltip
							></span>
							<span
								style="
									justify-content: center;
									border-right: none;
									flex: 1.2;
								"
								>Y轴偏移量<el-tooltip
									effect="dark"
									placement="right"
								>
									<span
										v-html="
											'1、只能填写数字；</br>2、填写正整数代表在Excel标题名称所在单元格的Y轴坐标基础上往下移动几个单元格；</br>3、填写负整数代表在Excel标题名称所在单元格的Y轴坐标基础上往上移动几个单元格；'
										"
										slot="content"
									></span>
									<i
										class="icon iconfont icon-wenhao"
									></i> </el-tooltip
							></span>
						</div>

						<div v-for="(item, index) in list" :key="index">
							<span style="text-indent: 5px">{{
								item.showName
							}}</span>
							<span style="justify-content: center; flex: 1.2"
								><el-checkbox
									:true-label="1"
									:false-label="0"
									v-model="item.fieldStart"
								></el-checkbox
							></span>
							<span style="justify-content: center"
								><input
									style="width: 90%"
									v-model="item.excelName"
									type="text"
							/></span>
							<span style="justify-content: center; flex: 1.2"
								><input
									onkeyup="value=value.replace(/[^\-?\d]/g,'')"
									v-model="item.acrossOffset"
									type="text"
							/></span>
							<span style="justify-content: center; flex: 1.2"
								><input
									onkeyup="value=value.replace(/[^\-?\d]/g,'')"
									v-model="item.endlongOffset"
									type="text"
							/></span>
						</div>
					</div>
					<el-form-item label="请选择文件:">
						<el-upload
							class="upload-demo"
							ref="upload"
							action="Fake Action"
							:file-list="fileList"
							:auto-upload="false"
							:on-change="handleChange"
							name="files"
						>
							<el-button
								id="v-step-2"
								slot="trigger"
								size="small"
								type="primary"
								>选取文件</el-button
							>
						</el-upload>
					</el-form-item>
				</el-form>

				<div class="demo-drawer__footer">
					<el-button
						id="v-step-3"
						@click="opstInputNew"
						type="primary"
						>导 入</el-button
					>

					<el-button @click="$emit('closeVisible')">关 闭</el-button>
				</div>
			</div>
			<v-tour :options="myOptions" name="myTour" :steps="steps"></v-tour>
		</div>
	</el-drawer>
</template>

<script>
import moment from 'moment';

import { financeSubjectTemplate, inputNew } from '@/api/index.js';
export default {
	name: 'diyImportDrawer',
	props: {
		diyImportVisible: {
			type: Boolean,
			required: true,
		},
		clientName: {
			type: String,
			required: true,
		},
		FinanceSubjectTemplateType: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			ruleForm: { financeDate: '', templateTypeId: '1', file: {} },
			fileList: [],
			checked: true,
			list: [],
			rtnDataList: [],
			steps: [
				{
					target: '#v-step-0',
					content: `填写对应的财务日期`,
				},
				{
					target: '#v-step-1',
					content: '选择模板对应的类型',
				},
				{
					target: '#v-step-2',
					content: '选择数据文件',
				},
				{
					target: '#v-step-3',
					content: '点击导入',
					params: {
						placement: 'top',
					},
				},
			],
			myOptions: {
				useKeyboardNavigation: false, //不使用←、→和ESC键来导航tour
				startTimeout: 1000, //1秒后执行
				labels: {
					buttonSkip: '跳过',
					buttonPrevious: '上一步',
					buttonNext: '下一步',
					buttonStop: '关闭',
				},
			},
		};
	},

	methods: {
		open() {
			this.change('1');
		},

		async change(val) {
			const res = await financeSubjectTemplate({
				templateTypeId: val,
				pageNo: 1,
				pageSize: 999,
			});
			this.list = res.list;
			this.$nextTick(() => {
				if (!localStorage.getItem('C')) {
					localStorage.setItem('C', 1);
					this.$tours['myTour'].start();
				}
			});
		},
		handleRemove(file, fileList, index, name) {
			this.ruleForm[name].splice(index, 1);
		},
		handleChange(file, fileList) {
			if (fileList.length > 0) {
				this.fileList = [fileList[fileList.length - 1]];
				this.ruleForm.file = file.raw;
			}
		},
		opstInputNew() {
			this.$refs['diyRuleForm'].validate(async (valid) => {
				if (valid) {
					let formData = new FormData();
					formData.append(
						'cooperationPresidentId',
						this.$store.state.userInfo.userVo.cooperationPresidentId
					);
					formData.append('file', this.ruleForm.file);
					formData.append(
						'financeDate',
						moment(this.ruleForm.financeDate)
							.endOf('month')
							.format('YYYY-MM-DD')
					);
					formData.append(
						'financeDateStr',
						moment(this.ruleForm.financeDate)
							.endOf('month')
							.format('YYYY-MM-DD')
					);
					formData.append(
						'templateTypeId',
						this.ruleForm.templateTypeId
					);
					formData.append('clientName', this.clientName);
					let list = this.list.filter((item) => item.fieldStart);

					list.forEach((element, index) => {
						formData.append(
							'financeSubjectTemplateList[' +
								index +
								'].fieldStart',
							element.fieldStart
						);
						formData.append(
							'financeSubjectTemplateList[' +
								index +
								'].mapField',
							element.mapField
						);
						formData.append(
							'financeSubjectTemplateList[' +
								index +
								'].excelName',
							element.excelName
						);
						formData.append(
							'financeSubjectTemplateList[' +
								index +
								'].acrossOffset',
							element.acrossOffset
						);
						formData.append(
							'financeSubjectTemplateList[' +
								index +
								'].endlongOffset',
							element.endlongOffset
						);
						formData.append(
							'financeSubjectTemplateList[' +
								index +
								'].templateTypeId',
							element.templateTypeId
						);
						formData.append(
							'financeSubjectTemplateList[' +
								index +
								'].templateTypeName',
							element.templateTypeName
						);
					});

					const res = await inputNew(formData);
					if (res.returncode == 1) {
						if (res.rtnDataList.length > 0) {
							this.rtnDataList = res.rtnDataList;
							this.$nextTick(() => {
								document
									.querySelector('.rtnDataList')
									.scrollIntoView({ behavior: 'smooth' });
							});
							this.$message({
								message: '导入失败',
								type: 'error',
							});
						} else {
							this.$message({
								message: res.message,
								type: 'error',
							});
						}
					} else {
						this.$confirm('提交成功', '提示', {
							confirmButtonText: '确定',
							type: 'success',
							showCancelButton: false,
						});
						this.$emit('closeVisible');
					}
				}
			});
		},
	},
	created() {},
	computed: {
		drawer_: {
			get() {
				return this.diyImportVisible;
			},
			set(v) {
				this.$emit('update:diyImportVisible', v);
			},
		},
	},
};
</script>
<style scoped>
::v-deep .demo-ruleForm {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
::v-deep .demo-ruleForm > div {
	width: 48%;
}
::v-deep .demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 20px;
}

::v-deep .demo-drawer__footer .el-button {
	width: 15%;
}

.diy-table {
	width: 100% !important;
	margin: 10px auto;
	border: 1px #e3e3e3 solid;
	font-size: 12px;
	display: flex;
	flex-wrap: wrap;
}

.diy-table div {
	width: 50%;
	display: flex;
	height: 40px;
	border-bottom: 1px solid #e3e3e3;
	box-sizing: border-box;
}

.diy-table div span {
	height: 100%;
	flex: 1;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	border-right: 1px solid #e3e3e3;
}

.diy-table div span input {
	width: 83%;
	height: 30px;
	border: 1px solid #dbdbdb;
	padding-left: 8px;
}

.diy-table div span:nth-child(1),
.diy-table div span:nth-child(6),
.diy-table div span:nth-child(3),
.diy-table div span:nth-child(8) {
	flex: 2;
	text-align: right;
}

.diy-table div:last-child {
	border-bottom: none;
}

.diy-table div:first-child {
	font-weight: 800;
	background: #f2f2f2;
	width: 100%;
}
</style>
